//#region IMPORTS

import { connect } from 'react-redux';
import Wrapper, { DispatchProps, StateProps } from './Wrapper';
import {
  BluetoothBroadcastStart,
  ConsoleLog,
  ReceivedActionFromNative,
  RequestBluetoothBroadcastStart,
  VersionFetch,
  SetAbleToBook,
  RequestReStart,
} from '../../ActionWrapper';
import {
  SetFirstRun,
  SetBluetoothId,
  UserFetch,
  SetTagId,
  PushNotificationRegisterFetch,
  SetFirstVisit,
  SatoAuthFetch,
  SetSatoToken,
  PushNotificationPermissionRequest,
  RequestBluetoothId,
  RequestDeviceToken,
  RequestTagId,
  SetLanguage,
} from '../../../user/ActionUser';
import { AppState } from '../../../../bootstrap/ReduxStore';

//#endregion

//#region REDUX BINDINGS

const mapStateToProps = (state: AppState): StateProps => ({
  userAction: state.user.action,
  userRegistrationStatus: state.user.cognitoUserRegistrationStatus,
  pushNotificationRegistrationStatus: state.user.pushNotificationRegistrationStatus,
  locale: state.user.locale,
  previousLocale: state.user.previousLocale,
  bluetoothId: state.user.bluetoothId,
  isBroadcasting: state.wrapper.isBroadcasting,
  tagId: state.user.tagId,
  isFirstRun: state.user.isFirstRun,
  deviceToken: state.user.deviceToken,
  firstAppVisit: state.user.firstAppVisit,
  satoAuthResponse: state.user.satoAuthResponse,
  satoToken: state.user.satoToken,
  bluetoothPermissionStatus: state.user.bluetoothPermissionStatus,
  pushNotificationPermissionStatus: state.user.pushNotificationPermissionStatus,
  version: state.wrapper.version,
  isAbleToBook: state.wrapper.isAbleToBook,
  bluetoothIdLoading: state.user.bluetoothIdLoading,
  deviceTokenLoading: state.user.deviceTokenLoading,
  tagIdLoading: state.user.tagIdLoading,
  action: state.wrapper.action,
  newUrl: state.wrapper.newUrl,
  newUrlWithParam: state.wrapper.newUrlWithParam,
});

const mapDispatchToProps: DispatchProps = {
  ReceivedActionFromNative,
  UserFetch,
  BluetoothBroadcastStart,
  SetBluetoothId,
  SetTagId,
  RequestBluetoothBroadcastStart,
  RequestBluetoothId,
  RequestTagId,
  RequestDeviceToken,
  PushNotificationRegisterFetch,
  SetFirstVisit,
  SetFirstRun,
  ConsoleLog,
  SatoAuthFetch,
  SetSatoToken,
  PushNotificationPermissionRequest,
  VersionFetch,
  SetAbleToBook,
  RequestReStart,
  SetLanguage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapper);

//#endregion
