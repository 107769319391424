//#region IMPORT
// PACKAGE IMPORT
import { ReducersMapObject, AnyAction } from 'redux';
import { connectRouter, LocationChangeAction } from 'connected-react-router';
import { History } from 'history';

// LOCAL CONFIG
import wrapper from '../modules/wrapper/ReducerWrapper';
import congestion from '../modules/congestion/ReducerCongestion';
import user from '../modules/user/ReducerUser';
import { JalAppState } from './interface';
//#endregion

export default (history: History): ReducersMapObject<JalAppState, AnyAction & LocationChangeAction> => ({
  router: connectRouter(history),
  wrapper,
  congestion,
  user,
});
