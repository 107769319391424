//#region IMPORT
import env from '../../../config/environment';

//#endregion

//#region ACTION STRINGS

// BLUETOOTH
export const NEW_DEVICE_TOKEN = 'NEW_DEVICE_TOKEN';
export const NEW_BLUETOOTH_ID = 'NEW_BLUETOOTH_ID';
export const NEW_TAG_ID = 'NEW_TAG_ID';
export const BLUETOOTH_BROADCAST_START = 'BLUETOOTH_BROADCAST_START';
export const BLUETOOTH_BROADCAST_STOP = 'BLUETOOTH_BROADCAST_STOP';
export const REQUEST_BLUETOOTH_BROADCAST_START = 'REQUEST_BLUETOOTH_BROADCAST_START';

// GENERAL XAMARIN ACTIONS
export const SEND_ACTION_TO_NATIVE = 'SEND_ACTION_TO_NATIVE';
export const RECEIVED_ACTION_FROM_NATIVE = 'RECEIVED_ACTION_FROM_NATIVE';

export const SEND_ACTION_TO_NATIVE_CLEAR = 'SEND_ACTION_TO_NATIVE_CLEAR';
export const RECEIVED_ACTION_FROM_NATIVE_CLEAR = 'RECEIVED_ACTION_FROM_NATIVE_CLEAR';

export const SEND_ACTION_TO_NATIVE_ERROR = 'SEND_ACTION_TO_NATIVE_ERROR';
export const RECEIVED_ACTION_FROM_NATIVE_ERROR = 'RECEIVED_ACTION_FROM_NATIVE_ERROR';

export const SEND_ACTION_TO_NATIVE_ERROR_CLEAR = 'SEND_ACTION_TO_NATIVE_ERROR_CLEAR';
export const RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR = 'RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR';

export const UNKNOWN_ACTION = 'UNKNOWN_ACTION';

// PUSH NOTIFICATION
export const REGISTER_ENDPOINTS_FETCH = 'REGISTER_ENDPOINTS_FETCH';
export const REGISTER_ENDPOINTS_RESPONSE = 'REGISTER_ENDPOINTS_RESPONSE';
export const UNREGISTER_ENDPOINT = 'UNREGISTER_ENDPOINT';
export const NEW_PUSH_NOTIFICATION_STATUS = 'NEW_PUSH_NOTIFICATION_STATUS';

// VERSIONING
export const VERSION_FETCH = 'VERSION_FETCH';
export const VERSION_SUCCESS = 'VERSION_SUCCESS';

// FEATURE
export const FEATURE_FETCH = 'FEATURE_FETCH';
export const FEATURE_SUCCESS = 'FEATURE_SUCCESS';
// NATIVE NAVIGATION
export const PAGE_NAVIGATED = 'PAGE_NAVIGATED';
export const NEW_SCREEN_URL = 'NEW_SCREEN_URL';
export const NEW_SCREEN_URL_WITH_PARAM = 'NEW_SCREEN_URL_WITH_PARAM';

// MISC
export const CONSOLE_LOG = 'CONSOLE_LOG';
export const SET_ABLE_TO_BOOK = 'SET_ABLE_TO_BOOK';
export const SET_MESSAGE_QUEUE = 'SET_MESSAGE_QUEUE';
export const REQUEST_RESTART = 'REQUEST_RESTART';

//#endregion

//#region MISC

export const COGNITO_CREDENTIALS = {
  username: env.credentialUserName,
  password: env.credentialUserPassword,
};

//#endregion
