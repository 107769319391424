//#region IMPORT
// PACKAGE IMPORT
import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import 'moment/locale/ja';

//#endregion

/**
 * Extracts the values from a string enum and returns the array of values of the enum.
 * @param {E} enumType
 * @returns {unknown[]}
 */
export function extractEnumValues<E>(enumType: E): E[] {
  return [...Object.values(enumType).filter(statusType => typeof statusType === 'string')];
}

/**
 * Call the url using GET
 * @param {string} url
 * @param {Record<string, unknown>} data
 * @returns {Promise<AxiosResponse>}
 */
export function get(url: string, data: Record<string, unknown>): Promise<AxiosResponse> {
  if (data && data.token) {
    const { token, ...dataWithoutToken } = data;
    return axios.get(`${url}?${qs.stringify(dataWithoutToken)}`, { headers: { Authorization: `Bearer ${token}` } });
  }
  return axios.get(`${url}?${qs.stringify(data)}`);
}

/**
 * Posts data using Axios
 * @param {string} url
 * @param {T} data
 * @returns {Promise<AxiosResponse<R>>}
 */
export function post<T, R = Record<string, unknown>>(url: string, data: T): Promise<AxiosResponse<R>> {
  return axios.post(`${url}`, data);
}
