//#region IMPORT
// LOCAL CONFIG
import {
  CongestionActionTypes,
  AreaList,
  CONGESTION_LIST_SUCCESS,
  CongestionListSuccess,
  CONGESTION_LIST_FETCH,
  CongestionListFetch,
  CONGESTION_LIST_FAILED,
  CONGESTION_LIST_CLEAR,
  CongestionListFailed,
  CONGESTION_SUBSCRIPTION_FAILED,
  CONGESTION_SUBSCRIPTION_SUCCESS,
} from './constants';
//#endregion

//#region INTERFACE
export interface CongestionListInitialState {
  congestionListParam?: object;
  congestionListResponse?: AreaList;
  congestionListError?: object;
  congestionListLoading: boolean;
}

export interface InitialState extends CongestionListInitialState {
  action: string;
}
//#endregion

//#region INITIAL STATE
const congestionListInitialState: CongestionListInitialState = {
  congestionListError: undefined,
  congestionListLoading: false,
  congestionListParam: undefined,
  congestionListResponse: undefined,
};

const initialState: InitialState = {
  ...congestionListInitialState,
  action: '',
};
//#endregion

//#region REDUCER
export default (state = initialState, action: CongestionActionTypes): InitialState => {
  const actions = {
    [CONGESTION_LIST_FETCH]: (): InitialState => ({
      ...state,
      congestionListParam: (action as CongestionListFetch).payload,
      congestionListLoading: true,
      action: action.type,
    }),
    [CONGESTION_LIST_SUCCESS]: (): InitialState => ({
      ...state,
      congestionListResponse: (action as CongestionListSuccess).payload,
      congestionListLoading: false,
      action: action.type,
    }),
    [CONGESTION_LIST_FAILED]: (): InitialState => ({
      ...state,
      congestionListError: (action as CongestionListFailed).payload,
      congestionListLoading: false,
      action: action.type,
    }),
    [CONGESTION_LIST_CLEAR]: (): InitialState => ({
      ...state,
      ...congestionListInitialState,
    }),
    [CONGESTION_SUBSCRIPTION_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [CONGESTION_SUBSCRIPTION_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    DEFAULT: (): InitialState => state,
  };
  return (actions[action.type] || actions.DEFAULT)();
};
//#endregion
