/* eslint-disable @typescript-eslint/explicit-function-return-type */
//#region IMPORT
// PACKAGE IMPORT
import { takeLatest, put } from 'redux-saga/effects';
import API, { graphqlOperation } from '@aws-amplify/api';

// LOCAL CONFIG
import { CongestionListFetch, CONGESTION_LIST_FETCH, CongestionListResponse, queries } from './constants';
import { congestionListSuccess, congestionListFailed } from './ActionCongestion';

//#endregion

//#region
// CONGESTION_LIST_FETCH
function* workerCongestionList(action: CongestionListFetch) {
  const invalidFilterError = 'Invalid Filter Type';

  try {
    const { airport_id, ...variables } = action.payload;
    console.log("variables: ", variables)
    const query = queries.ListArea;
    const response = yield API.graphql(graphqlOperation(query, {airport_id: airport_id, variables}));
    const data = (response.data as CongestionListResponse).listArea;
    if (typeof data === 'string') {
      yield put(congestionListFailed({ message: invalidFilterError }));
      return;
    }

    yield put(congestionListSuccess(data));
  } catch (e) {
    yield put(congestionListFailed(e));
  }
}

//#endregion

export default [takeLatest(CONGESTION_LIST_FETCH, workerCongestionList)];
