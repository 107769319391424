//#region IMPORT
import { AirportsGeoLocationsType } from './interface';
//#endrigion

//#region AIRPORT GEOLOCATION

// for debug
export const JPN_GEOLOCATIONS: AirportsGeoLocationsType = {
  'haneda': {
    eastLongitude: 153.5912,
    westLongitude: 122.5557,
    northLatitude: 45.3326,
    southLatitude: 20.2531,
  },
  'narita': {
    eastLongitude: 153.5912,
    westLongitude: 122.5557,
    northLatitude: 45.3326,
    southLatitude: 20.2531,
  }
}

// for stage and release
export const AIRPORT_GEOLOCATIONS: AirportsGeoLocationsType = {
  'haneda': {
    eastLongitude: 139.794178,
    westLongitude: 139.765055,
    northLatitude: 35.556585,
    southLatitude: 35.541044,
  },
  'narita': { // have to fix location numbers
    eastLongitude: 139.794178,
    westLongitude: 139.765055,
    northLatitude: 35.556585,
    southLatitude: 35.541044,
  } 
}
////#endregion
