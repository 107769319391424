/* eslint-disable @typescript-eslint/explicit-function-return-type */
//#region IMPORTS

// PACKAGE IMPORTS
import { SagaIterator } from 'redux-saga';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import Auth from '@aws-amplify/auth';

// LOCAL CONFIG IMPORTS
import {
  BLUETOOTH_PERMISSION_REQUEST,
  BluetoothPermissionRequest,
  GET_USER_LOCATION_API,
  GET_USER_LOCATION_FETCH,
  GetUserLocationFetch,
  GetUserLocationResponse,
  MOCK_USER_INFO,
  PUSH_NOTIFICATION_PERMISSION_REQUEST,
  PUSH_NOTIFICATION_REGISTER_FETCH,
  PushNotificationPermissionRequest,
  PushNotificationsRegisterFetch,
  SATO_API_AUTH_URI,
  SATO_API_URI,
  SATO_AUTH_FETCH,
  SatoAuthFailType,
  SatoAuthFetch,
  SatoFailureType,
  USER_FETCH,
  UserFetch,
} from './constants';
import {
  GetUserLocationFailed,
  GetUserLocationSuccess,
  SatoAuthFailed,
  SatoAuthSuccess,
  UserFailed,
  UserSuccess,
} from './ActionUser';
import { REGISTER_ENDPOINTS_FETCH } from '../wrapper/constants';
import { SendActionToNative } from '../wrapper/ActionWrapper';
import { get, post } from '../../utils';
import { AppState } from '../../bootstrap/ReduxStore';

//#endregion

//#region SAGA WORKERS

//#region SATO

function* workerSagaGetUserLocation(action: GetUserLocationFetch): SagaIterator {
  const url = `${SATO_API_URI}${GET_USER_LOCATION_API}`;
  try {
    if (process.env.REACT_APP_ENV === 'development' /* && process.env.REACT_APP_PLATFORM !== 'XAMARIN'*/) {
      const tagIdSelector = (state: AppState) => state.user.tagId;
      const tagId = select(tagIdSelector);
      const mockResponse: GetUserLocationResponse = {
        // Disabled because of TypeScript limitation.
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        tagId,
        userInfo: MOCK_USER_INFO,
      };

      yield put(GetUserLocationSuccess(mockResponse));
    } else {
      if(action.payload.tagId) {
        const response: AxiosResponse<GetUserLocationResponse | SatoFailureType> = yield call(get, url, action.payload);

        if (response.status === 200 && (response.data as GetUserLocationResponse).userInfo.length > 0) {
          yield put(GetUserLocationSuccess(response.data as GetUserLocationResponse));
        } else {
          yield put(GetUserLocationFailed(response.data));
        }
      } else {
        yield put(GetUserLocationFailed("no tag id"));
      }
    }
  } catch (e) {
    yield put(GetUserLocationFailed(e));
  }
}

function* workerSagaSatoAuth(action: SatoAuthFetch): SagaIterator {
  const url = `${SATO_API_AUTH_URI}`;
  try {
    const response = yield call(post, url, qs.stringify(action.payload));

    if (response.status === 200) {
      yield put(SatoAuthSuccess(response.data));
    } else {
      yield put(SatoAuthFailed(response.data as SatoAuthFailType));
    }
  } catch (e) {
    yield put(SatoAuthFailed(e));
  }
}

//#endregion

//#region MISC

function* workerSagaUserRegister(action: UserFetch) {
  try {
    yield Auth.currentSession();
    yield put(UserSuccess());
  } catch (e) {
    try {
      yield Auth.signIn(action.payload.username, action.payload.password);
      yield put(UserSuccess());
    } catch (ex) {
      yield put(UserFailed(ex));
    }
  }
}

function* workerRegisterPushNotification(action: PushNotificationsRegisterFetch) {
  const newPayload = {
    type: REGISTER_ENDPOINTS_FETCH,
    payload: JSON.stringify(action.payload),
  };
  yield put(SendActionToNative(JSON.stringify(newPayload)));
}

function* workerPermissionRequest(action: BluetoothPermissionRequest | PushNotificationPermissionRequest) {
  yield put(SendActionToNative(JSON.stringify(action)));
}

//#endregion

//#endregion

const userWatcher = [
  takeLatest(USER_FETCH, workerSagaUserRegister),
  takeLatest(PUSH_NOTIFICATION_REGISTER_FETCH, workerRegisterPushNotification),
  takeLatest(GET_USER_LOCATION_FETCH, workerSagaGetUserLocation),
  takeLatest(SATO_AUTH_FETCH, workerSagaSatoAuth),
  takeLatest([BLUETOOTH_PERMISSION_REQUEST, PUSH_NOTIFICATION_PERMISSION_REQUEST], workerPermissionRequest),
];

export default userWatcher;
