//#region IMPORTS

import {
  BLUETOOTH_BROADCAST_START,
  BLUETOOTH_BROADCAST_STOP,
  CONSOLE_LOG,
  ConsoleLog,
  NEW_BLUETOOTH_ID,
  NEW_TAG_ID,
  NewBluetoothId,
  NewTagId,
  RECEIVED_ACTION_FROM_NATIVE,
  RECEIVED_ACTION_FROM_NATIVE_CLEAR,
  RECEIVED_ACTION_FROM_NATIVE_ERROR,
  RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR,
  ReceivedActionFromNative,
  ReceivedActionFromNativeError,
  REQUEST_BLUETOOTH_BROADCAST_START,
  SEND_ACTION_TO_NATIVE,
  SEND_ACTION_TO_NATIVE_CLEAR,
  SEND_ACTION_TO_NATIVE_ERROR,
  SEND_ACTION_TO_NATIVE_ERROR_CLEAR,
  SendActionToNative,
  SendActionToNativeError,
  UNKNOWN_ACTION,
  WrapperActionTypes,
  VERSION_SUCCESS,
  VERSION_FETCH,
  VersionSuccess,
  FeatureItem,
  FEATURE_FETCH,
  FEATURE_SUCCESS,
  FeatureSuccess,
  SET_ABLE_TO_BOOK,
  SET_MESSAGE_QUEUE,
  SetMessageQueue,
  NEW_SCREEN_URL,
  NewScreenUrl,
  NewUrlWithParam,
  NEW_SCREEN_URL_WITH_PARAM,
  NewScreenUrlWithParam,
  REQUEST_RESTART,
  AppVersion,
} from './constants';
import { FailureType } from '../../config/interface';

//#endregion

//#region INITIAL STATE

interface WrapperInitialState {
  receivedParams: string;
  sentParams: string;
  receivedError: string;
  sentError: FailureType;
  bluetoothId: string;
  tagId: string;
  isBroadcasting: boolean;
  isLoading: boolean;
  version: AppVersion;
  feature: FeatureItem[];
  isAbleToBook: boolean;
}

export interface InitialState extends WrapperInitialState {
  action: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  consoleLog: any;
  messageQueue: string[];
  newUrl: string;
  newUrlWithParam: NewUrlWithParam;
}

const wrapperInitialState: WrapperInitialState = {
  receivedParams: '',
  sentParams: '',
  receivedError: '',
  sentError: '',
  bluetoothId: '',
  tagId: '',
  isBroadcasting: false,
  isLoading: false,
  version: {
    js_version: process.env.REACT_APP_VERSION || '',
    ios_version: '',
    android_version: ''
  },
  feature: [],
  isAbleToBook: false,
};

const initialState: InitialState = {
  ...wrapperInitialState,
  action: '',
  consoleLog: '',
  messageQueue: [],
  newUrl: '',
  newUrlWithParam: {
    newPath: '',
  },
};

//#endregion

//#region REDUCER

function wrapperReducer(state = initialState, action: WrapperActionTypes): InitialState {
  const actions = {
    [SEND_ACTION_TO_NATIVE]: (): InitialState => ({
      ...state,
      action: action.type,
      sentParams: (action as SendActionToNative).payload,
    }),
    [RECEIVED_ACTION_FROM_NATIVE]: (): InitialState => ({
      ...state,
      action: action.type,
      receivedParams: (action as ReceivedActionFromNative).payload,
    }),
    [SEND_ACTION_TO_NATIVE_ERROR]: (): InitialState => ({
      ...state,
      action: action.type,
      sentError: (action as SendActionToNativeError).payload,
    }),
    [RECEIVED_ACTION_FROM_NATIVE_ERROR]: (): InitialState => ({
      ...state,
      action: action.type,
      receivedError: (action as ReceivedActionFromNativeError).payload,
    }),
    [RECEIVED_ACTION_FROM_NATIVE_CLEAR]: (): InitialState => ({
      ...state,
      action: action.type,
      receivedParams: initialState.receivedParams,
    }),
    [SEND_ACTION_TO_NATIVE_CLEAR]: (): InitialState => ({
      ...state,
      sentParams: initialState.sentParams,
      action: action.type,
    }),
    [RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR]: (): InitialState => ({
      ...state,
      receivedError: initialState.receivedError,
      action: action.type,
    }),
    [SEND_ACTION_TO_NATIVE_ERROR_CLEAR]: (): InitialState => ({
      ...state,
      action: action.type,
      sentError: initialState.sentError,
    }),
    [NEW_BLUETOOTH_ID]: (): InitialState => ({
      ...state,
      action: action.type,
      bluetoothId: (action as NewBluetoothId).payload,
    }),
    [NEW_TAG_ID]: (): InitialState => ({
      ...state,
      action: action.type,
      tagId: (action as NewTagId).payload,
    }),
    [BLUETOOTH_BROADCAST_START]: (): InitialState => ({
      ...state,
      action: action.type,
      isBroadcasting: true,
    }),
    [BLUETOOTH_BROADCAST_STOP]: (): InitialState => ({
      ...state,
      action: action.type,
      isBroadcasting: false,
    }),
    [UNKNOWN_ACTION]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [CONSOLE_LOG]: (): InitialState => ({
      ...state,
      action: action.type,
      consoleLog: (action as ConsoleLog).payload,
    }),
    [REQUEST_BLUETOOTH_BROADCAST_START]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [VERSION_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [VERSION_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      version: (action as VersionSuccess).payload,
    }),
    [SET_ABLE_TO_BOOK]: (): InitialState => ({
      ...state,
      action: action.type,
      isAbleToBook: true,
    }),
    [SET_MESSAGE_QUEUE]: (): InitialState => ({
      ...state,
      action: action.type,
      messageQueue: (action as SetMessageQueue).payload,
    }),
    [FEATURE_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [FEATURE_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      feature: (action as FeatureSuccess).payload,
    }),
    [NEW_SCREEN_URL]: (): InitialState => ({
      ...state,
      action: action.type,
      newUrl: (action as NewScreenUrl).payload,
    }),
    [NEW_SCREEN_URL_WITH_PARAM]: (): InitialState => ({
      ...state,
      action: action.type,
      newUrlWithParam: (action as NewScreenUrlWithParam).payload,
    }),
    [REQUEST_RESTART]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    DEFAULT: (): InitialState => state,
  };

  return (actions[action.type] || actions.DEFAULT)();
}

//#endregion

export default wrapperReducer;
