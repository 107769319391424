//#region IMPORTS

import {
  BLUETOOTH_PERMISSION_REQUEST,
  FirstVisitType,
  GET_USER_LOCATION_CLEAR,
  GET_USER_LOCATION_FAILED,
  GET_USER_LOCATION_FETCH,
  GET_USER_LOCATION_SUCCESS,
  GetUserLocationResponse,
  PermissionStatusTypes,
  PUSH_NOTIFICATION_PERMISSION_REQUEST,
  PUSH_NOTIFICATION_REGISTER_CLEAR,
  PUSH_NOTIFICATION_REGISTER_FAILED,
  PUSH_NOTIFICATION_REGISTER_FETCH,
  PUSH_NOTIFICATION_REGISTER_SUCCESS,
  RegisterPinpointOptions, REQUEST_BLUETOOTH_ID, REQUEST_DEVICE_TOKEN, REQUEST_TAG_ID,
  SATO_AUTH_CLEAR,
  SATO_AUTH_FAILED,
  SATO_AUTH_FETCH,
  SATO_AUTH_SUCCESS,
  SatoAreaInfo,
  SatoAuthFailType,
  SatoAuthParam,
  SatoAuthResponse,
  SatoFailureType,
  SET_BLUETOOTH_ID,
  SET_BT_PERMISSION_STATUS,
  SET_DEVICE_TOKEN,
  SET_FIRST_RUN,
  SET_FIRST_VISIT,
  SET_LANGUAGE,
  SET_PN_PERMISSION_STATUS,
  SET_SATO_TOKEN,
  SET_TAG_ID,
  USER_CLEAR,
  USER_FAILED,
  USER_FETCH,
  USER_SUCCESS,
  UserActionTypes,
  UserRegistration,
  GEO_LOCATION,
  GetLocationType,
  LocationInfo,
} from './constants';
import { FailureType, SupportedLanguages } from '../../config/interface';

//#endregion

//#region ACTION TYPES

//#region DEVICE IDS

export const RequestBluetoothId = (): UserActionTypes => ({
  type: REQUEST_BLUETOOTH_ID,
});

export const RequestDeviceToken = (): UserActionTypes => ({
  type: REQUEST_DEVICE_TOKEN,
});

export const RequestTagId = (): UserActionTypes => ({
  type: REQUEST_TAG_ID,
});

export const SetBluetoothId = (payload: string): UserActionTypes => ({
  type: SET_BLUETOOTH_ID,
  payload,
});

export const SetTagId = (payload: string): UserActionTypes => ({
  type: SET_TAG_ID,
  payload,
});

export const SetDeviceToken = (payload: string): UserActionTypes => ({
  type: SET_DEVICE_TOKEN,
  payload,
});

export const SetSatoToken = (payload: string): UserActionTypes => ({
  type: SET_SATO_TOKEN,
  payload,
});

//#endregion

//#region PUSH NOTIFICATIONS

export const PushNotificationRegisterFetch = (payload: RegisterPinpointOptions): UserActionTypes => ({
  type: PUSH_NOTIFICATION_REGISTER_FETCH,
  payload,
});

export const PushNotificationRegisterSuccess = (): UserActionTypes => ({
  type: PUSH_NOTIFICATION_REGISTER_SUCCESS,
});

export const PushNotificationRegisterFailed = (payload: FailureType): UserActionTypes => ({
  type: PUSH_NOTIFICATION_REGISTER_FAILED,
  payload,
});

export const PushNotificationRegisterClear = (): UserActionTypes => ({
  type: PUSH_NOTIFICATION_REGISTER_CLEAR,
});

//#endregion

//#region USER

export const UserFetch = (payload: UserRegistration): UserActionTypes => ({
  type: USER_FETCH,
  payload,
});

export const UserSuccess = (): UserActionTypes => ({
  type: USER_SUCCESS,
});

export const UserFailed = (payload: FailureType): UserActionTypes => ({
  type: USER_FAILED,
  payload,
});

export const UserClear = (): UserActionTypes => ({
  type: USER_CLEAR,
});

export const SetFirstRun = (): UserActionTypes => ({
  type: SET_FIRST_RUN,
});

//#endregion

//#region LOCALE

export const SetLanguage = (payload: SupportedLanguages): UserActionTypes => ({
  type: SET_LANGUAGE,
  payload,
});

//#endregion

//#region ORDER CONFIRM

export const SetFirstVisit = (payload: FirstVisitType): UserActionTypes => ({
  type: SET_FIRST_VISIT,
  payload,
});

//#endregion

//#region GET USER'S CURRENT LOCATION

export const GetUserLocationFetch = (payload: SatoAreaInfo): UserActionTypes => ({
  type: GET_USER_LOCATION_FETCH,
  payload,
});

export const GetUserLocationSuccess = (payload: GetUserLocationResponse): UserActionTypes => ({
  type: GET_USER_LOCATION_SUCCESS,
  payload,
});

export const GetUserLocationFailed = (payload: SatoFailureType | FailureType): UserActionTypes => ({
  type: GET_USER_LOCATION_FAILED,
  payload,
});

export const GetUserLocationClear = (): UserActionTypes => ({
  type: GET_USER_LOCATION_CLEAR,
});

export const SetGeoLocation = (payload: LocationInfo): GetLocationType => ({
  type: GEO_LOCATION,
  payload,
});

//#endregion

//#region SATO AUTH

export const SatoAuthFetch = (payload: SatoAuthParam): UserActionTypes => ({
  type: SATO_AUTH_FETCH,
  payload,
});

export const SatoAuthSuccess = (payload: SatoAuthResponse): UserActionTypes => ({
  type: SATO_AUTH_SUCCESS,
  payload,
});

export const SatoAuthFailed = (payload: SatoAuthFailType): UserActionTypes => ({
  type: SATO_AUTH_FAILED,
  payload,
});

export const SatoAuthClear = (): UserActionTypes => ({
  type: SATO_AUTH_CLEAR,
});

//#endregion

//#region DEVICE PERMISSIONS

export const BluetoothPermissionRequest = (): UserActionTypes => ({
  type: BLUETOOTH_PERMISSION_REQUEST,
});

export const PushNotificationPermissionRequest = (): UserActionTypes => ({
  type: PUSH_NOTIFICATION_PERMISSION_REQUEST,
});

export const SetBtPermissionStatus = (payload: PermissionStatusTypes): UserActionTypes => ({
  type: SET_BT_PERMISSION_STATUS,
  payload,
});

export const SetPnPermissionStatus = (payload: PermissionStatusTypes): UserActionTypes => ({
  type: SET_PN_PERMISSION_STATUS,
  payload,
});

//#endregion

//#endregion
