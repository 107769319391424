//#region IMPORTS

// PACKAGE IMPORTS
import { SagaIterator } from 'redux-saga';
import Axios, { AxiosResponse } from 'axios';
import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
// LOCAL CONFIGS
import {
  BluetoothBroadcastStart,
  BluetoothBroadcastStop,
  NewScreenUrl,
  NewScreenUrlWithParam,
  ReceiveActionFromNativeError,
  SendActionToNative,
  SendActionToNativeError,
  SetMessageQueue,
  UnknownAction,
  VersionSuccess,
  FeatureSuccess,
} from './ActionWrapper';
import {
  BLUETOOTH_BROADCAST_START,
  BLUETOOTH_BROADCAST_STOP,
  NativeAction,
  NEW_BLUETOOTH_ID,
  NEW_DEVICE_TOKEN,
  NEW_TAG_ID,
  RECEIVED_ACTION_FROM_NATIVE,
  ReceivedActionFromNative,
  REGISTER_ENDPOINTS_RESPONSE,
  REQUEST_BLUETOOTH_BROADCAST_START,
  SEND_ACTION_TO_NATIVE,
  VERSION_FETCH,
  SendActionToNative as SendActionToNativeAction,
  FeatureItem,
  FEATURE_FETCH,
  NEW_PUSH_NOTIFICATION_STATUS,
  PAGE_NAVIGATED,
  NEW_SCREEN_URL,
  NEW_SCREEN_URL_WITH_PARAM,
  NewUrlWithParam,
  REQUEST_RESTART,
  AppVersion,
} from './constants';
import {
  PushNotificationRegisterFailed,
  PushNotificationRegisterSuccess,
  SetBluetoothId,
  SetBtPermissionStatus,
  SetDeviceToken,
  SetPnPermissionStatus,
  SetTagId,
  SetGeoLocation,
} from '../user/ActionUser';
import {
  BLUETOOTH_PERMISSION_RESPONSE,
  PermissionStatusTypes,
  PUSH_NOTIFICATION_PERMISSION_RESPONSE,
  GEO_LOCATION,
  REQUEST_BLUETOOTH_ID,
  REQUEST_DEVICE_TOKEN,
  REQUEST_TAG_ID,
} from '../user/constants';
import { LocationInfo } from '../user/constants';

import env from '../../config/environment';
import { AppState } from '../../bootstrap/ReduxStore';
//#endregion

//#region SELECTOR

const getQueue = (state: AppState) => state.wrapper.messageQueue;

//#endregion

//#region SAGA WORKERS

//#region XAMARIN
function* workerSagaSendToNative(action: SendActionToNativeAction): SagaIterator {
  try {
    // Sends action to native. Function in index.html returns false if the injected function is unavailable.
    const result = yield call(window.sendToNative, action.payload);
    if (!result) {
      // Add the item to message queue. Send it when the page is navigated.
      const messageQueue: string[] = yield select(getQueue);

      yield put(SetMessageQueue([...messageQueue, action.payload]));
    }
  } catch (e) {
    yield put(SendActionToNativeError(e.message));
  }
}

function* workerSageReceiveFromNative(action: ReceivedActionFromNative): SagaIterator {
  try {
    const nativeAction: NativeAction = JSON.parse(action.payload);

    // Mini router to route Xamarin Actions.
    switch (nativeAction.type) {
      case PAGE_NAVIGATED: {
        const messageQueue: string[] = yield select(getQueue);

        for (let i = 0; i < messageQueue.length; i++) {
          yield put(SendActionToNative(messageQueue[i]));
        }

        yield put(SetMessageQueue([]));
        break;
      }
      case NEW_SCREEN_URL: {
        yield put(NewScreenUrl(nativeAction.payload));
        break;
      }
      case NEW_SCREEN_URL_WITH_PARAM: {
        yield put(NewScreenUrlWithParam((nativeAction.payload as unknown) as NewUrlWithParam));
        break;
      }
      case NEW_DEVICE_TOKEN:
        yield put(SetDeviceToken(nativeAction.payload));
        break;
      case NEW_BLUETOOTH_ID:
        yield put(SetBluetoothId(nativeAction.payload));
        break;
      case NEW_TAG_ID:
        yield put(SetTagId(nativeAction.payload));
        break;
      case REGISTER_ENDPOINTS_RESPONSE:
        nativeAction.payload === 'Accepted'
          ? yield put(PushNotificationRegisterSuccess())
          : yield put(PushNotificationRegisterFailed(nativeAction.payload));
        break;
      case BLUETOOTH_BROADCAST_START:
        yield put(BluetoothBroadcastStart());
        break;
      case BLUETOOTH_BROADCAST_STOP:
        yield put(BluetoothBroadcastStop());
        break;
      case REQUEST_TAG_ID:
        if (nativeAction.payload !== '') yield put(SetTagId(nativeAction.payload));
        break;
      case REQUEST_BLUETOOTH_ID:
        if (nativeAction.payload !== '') yield put(SetBluetoothId(nativeAction.payload));
        break;
      case REQUEST_DEVICE_TOKEN:
        if (nativeAction.payload !== '') yield put(SetDeviceToken(nativeAction.payload));
        break;
      case BLUETOOTH_PERMISSION_RESPONSE: {
        const newStatus: PermissionStatusTypes =
          nativeAction.payload === 'Success' ? PermissionStatusTypes.GRANTED : PermissionStatusTypes.ERROR_OR_REJECTED;
        yield put(SetBtPermissionStatus(newStatus));
        break;
      }
      case PUSH_NOTIFICATION_PERMISSION_RESPONSE: {
        const newStatus: PermissionStatusTypes =
          nativeAction.payload === 'Success' ? PermissionStatusTypes.GRANTED : PermissionStatusTypes.ERROR_OR_REJECTED;
        yield put(SetPnPermissionStatus(newStatus));
        break;
      }
      case GEO_LOCATION:
        if (nativeAction.payload !== '') {
          const location = nativeAction.payload as LocationInfo
          yield put(SetGeoLocation(location));
        }
        break;
      case NEW_PUSH_NOTIFICATION_STATUS: {
        yield put(SetPnPermissionStatus(nativeAction.payload as PermissionStatusTypes));
        break;
      }
      default:
        yield put(UnknownAction(nativeAction));
        break;
    }
  } catch (e) {
    yield put(ReceiveActionFromNativeError(e.message));
  }
}

function* workerSagaRequestToNative(action: unknown): SagaIterator {
  const payload = JSON.stringify(action);
  yield put(SendActionToNative(payload));
}

function* workerSagaVersionFetch(): SagaIterator {
  try {
    const response: AxiosResponse<AppVersion> = yield call(Axios.get, `${env.versionUrl}?${Date.now()}`);
    if (response.status === 200 && response.data && response.data.js_version) {
      yield put(VersionSuccess(response.data));
    }
  } catch {}
}

function* workerSagaFeatureFetch(): SagaIterator {
  try {
    const response: AxiosResponse<FeatureItem[]> = yield call(Axios.get, `${env.featureUrl}?${Date.now()}`);
    if (response.status === 200 && response.data) {
      yield put(FeatureSuccess(response.data));
    }
  } catch {}
}

//#endregion

//#region REQUEST FOR IDS

//#endregion

//#region SUBSCRIPTIONS

//#endregion

//#endregion

const wrapperWatcher = [
  takeEvery(SEND_ACTION_TO_NATIVE, workerSagaSendToNative),
  takeEvery(RECEIVED_ACTION_FROM_NATIVE, workerSageReceiveFromNative),
  takeLatest(
    [REQUEST_BLUETOOTH_BROADCAST_START, REQUEST_TAG_ID, REQUEST_BLUETOOTH_ID, REQUEST_DEVICE_TOKEN, REQUEST_RESTART],
    workerSagaRequestToNative
  ),
  takeEvery(VERSION_FETCH, workerSagaVersionFetch),
  takeEvery(FEATURE_FETCH, workerSagaFeatureFetch),
];

export default wrapperWatcher;
