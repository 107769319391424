//#region IMPORT
// PACKAGE IMPORT
import { History, Location } from 'history';
import { RouterState } from 'connected-react-router';

// LOCAL CONFIG
import { InitialState as WrapperState } from '../modules/wrapper/ReducerWrapper';
import { InitialState as CongestionState } from '../modules/congestion/ReducerCongestion';
import { InitialState as UserState } from '../modules/user/ReducerUser';

//#endregion

export interface Match<P> {
  params: P;
  isExact: boolean;
  path: string;
  url: string;
}

export interface ModuleBaseProps {
  history: History;
  location: Location;
}

export interface JalAppState {
  router: RouterState;
  wrapper: WrapperState;
  congestion: CongestionState;
  user: UserState;
}

export interface Environment {
  poolId: string;
  region: string;
  identityPoolRegion: string;
  userPoolId: string;
  mandatorySignIn: boolean;
  userPoolWebClientId: string;
  appsyncEndpoint: string;
  appsyncRegion: string;
  appsyncAuthenticationType: 'API_KEY';
  appsyncApiKey: string;
  credentialUserName: string;
  credentialUserPassword: string;
  menuListUrl: string;
  versionUrl: string;
  featureUrl: string;
  satoApiUrl: string;
  satoAuthUrl: string;
  airportsLocations: AirportsGeoLocationsType;
}

export type FailureType = Record<string, unknown> | string;

export type GraphQLSubscriptionResult = {
  provider: unknown;
  value: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
  };
};

export enum RESET {
  ALL = '@@reset/ALL',
}
//#endregion

//#region ENUMS

export enum SupportedLanguages {
  EN = 'en',
  JA = 'ja',
}

//#endregion

//#region Lounge Locations

export type GeoLocationType = {
  eastLongitude: number,
  westLongitude: number,
  northLatitude: number,
  southLatitude: number,
}

export interface AirportsGeoLocationsType {
  [id:string]: GeoLocationType
}

//#endregio