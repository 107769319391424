//#region IMPORTS
// PACKAGE IMPORT
import React from 'react';
import classnames from 'classnames';
import { injectIntl, IntlShape } from 'react-intl';

// LOCAL COMPONENT
import Spinner from '../Spinner';
import { ButtonTheme } from './Buttontypes';
import './_index.scss';

//#endregion

//#region INTERFACE
type AnchorType = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
type ButtonType = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
type DEFAULT = AnchorType & ButtonType;

interface OwnProps extends DEFAULT {
  label?: string;
  icon?: React.ReactElement;
  showSpinner?: boolean;
  intl: IntlShape;
  theme?: ButtonTheme;
}

//#endregion

//#region COMPONENT
function Button({ className, label, icon, intl, showSpinner, href, theme, ...others }: OwnProps): React.ReactElement {
  const classes = classnames(
    'button',
    { 'button--disabled': showSpinner },
    { 'button--primary': theme === ButtonTheme.Primary },
    { 'button--primary-outline': theme === ButtonTheme.PrimaryOutline },
    { 'button--primary-transparent': theme === ButtonTheme.PrimaryTransparent },
    { 'button--secondary': theme === ButtonTheme.Secondary },
    { 'button--secondary-outline': theme === ButtonTheme.SecondaryOutline },
    { 'button--secondary-transparent': theme === ButtonTheme.SecondaryTransparent },
    { 'button--danger': theme === ButtonTheme.Danger },
    { 'button--danger-outline': theme === ButtonTheme.DangerOutline },
    { 'button--danger-transparent': theme === ButtonTheme.DangerTransparent },
    className
  );

  return (
    <>
      {href ? (
        <a className={classes} href={href} {...others}>
          <div className="button__content">
            {icon && icon}
            {label && intl.formatMessage({ id: label, defaultMessage: label })}
          </div>
        </a>
      ) : (
        <button disabled={showSpinner} className={classes} {...others}>
          <div className="button__content">
            {icon && !showSpinner && icon}
            {label && !showSpinner && intl.formatMessage({ id: label, defaultMessage: label })}
            {showSpinner && <Spinner />}
          </div>
        </button>
      )}
    </>
  );
}

//#endregion

export default injectIntl(Button);
