//#region IMPORTS
// LOCAL CONFIG
import {
  CONGESTION_LIST_FETCH,
  CONGESTION_LIST_SUCCESS,
  CONGESTION_LIST_FAILED,
  ON_CONGESTION_UPDATE,
  CONGESTION_SUBSCRIPTION_SUCCESS,
  CONGESTION_SUBSCRIPTION_FAILED,
} from './StringCongestion';

//#endregion

//#region ENUM and GENERAL
export enum CongestionLevel {
  Closed = 1,
  Vacant = 2,
  Crowded = 3,
}

export enum AreaCode {
  Main = '1',
  Sub = '2',
}

export type FailureType = Record<string, unknown> | string;

//#endregion

//#region TYPE
export interface Area {
  code: string;
  congestion_level: number;
  updated_at: string;
}
export interface AreaList {
  items?: Area[];
  nextToken?: string;
}
interface ListAreaObject {
  code: number;
  name: string;
  level?: number;
}
export const ListArea: Record<string, ListAreaObject> = {
  '1': {
    code: 1,
    name: 'Main Area',
  },
  '2': {
    code: 2,
    name: 'Sub Area',
  },
};

//#endregion

//#region ACTION TYPE
// LIST
export interface CongestionListParam {
  airport_id: string;
  limit?: number;
  nextToken?: string;
}
export interface CongestionListFetch {
  type: typeof CONGESTION_LIST_FETCH;
  payload: CongestionListParam;
}
export interface CongestionListSuccess {
  type: typeof CONGESTION_LIST_SUCCESS;
  payload: AreaList;
}
export interface CongestionListFailed {
  type: typeof CONGESTION_LIST_FAILED;
  payload: {};
}
export type CongestionListResponse = {
  listArea: AreaList;
};

// Congestion Subscription
export interface OnCongestionUpdate {
  type: typeof ON_CONGESTION_UPDATE;
  payload: Area;
}

export interface CongestionSubscriptionSuccess {
  type: typeof CONGESTION_SUBSCRIPTION_SUCCESS;
}

export interface CongestionSubscriptionFailed {
  type: typeof CONGESTION_SUBSCRIPTION_FAILED;
  payload: FailureType;
}

export type CongestionActionTypes =
  | CongestionListFetch
  | CongestionListSuccess
  | CongestionListFailed
  | CongestionSubscriptionSuccess
  | CongestionSubscriptionFailed;

//#endregion
