export enum ButtonTheme {
  Default,
  Primary,
  PrimaryTransparent,
  PrimaryOutline,
  Secondary,
  SecondaryTransparent,
  SecondaryOutline,
  Danger,
  DangerTransparent,
  DangerOutline,
}
