//#region IMPORT
// LOCAL CONFIG
import {
  AreaList,
  CONGESTION_LIST_FAILED,
  CONGESTION_LIST_FETCH,
  CONGESTION_LIST_SUCCESS,
  CONGESTION_SUBSCRIPTION_FAILED,
  CONGESTION_SUBSCRIPTION_SUCCESS,
  CongestionActionTypes,
  CongestionListParam,
  FailureType,
} from './constants';

//#endregion

//#region ACTION
// CONGESTION LIST
export const congestionListFetch = (payload: CongestionListParam): CongestionActionTypes => ({
  type: CONGESTION_LIST_FETCH,
  payload,
});
export const congestionListSuccess = (payload: AreaList): CongestionActionTypes => ({
  type: CONGESTION_LIST_SUCCESS,
  payload,
});
export const congestionListFailed = (payload: FailureType): CongestionActionTypes => ({
  type: CONGESTION_LIST_FAILED,
  payload,
});
export const CongestionSubscriptionSuccess = (): CongestionActionTypes => ({
  type: CONGESTION_SUBSCRIPTION_SUCCESS,
});
export const CongestionSubscriptionFailed = (payload: FailureType): CongestionActionTypes => ({
  type: CONGESTION_SUBSCRIPTION_FAILED,
  payload,
});
//#endregion
