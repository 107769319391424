//#region IMPORTS

import {
  BLUETOOTH_PERMISSION_REQUEST,
  FirstVisitType,
  GET_USER_LOCATION_CLEAR,
  GET_USER_LOCATION_FAILED,
  GET_USER_LOCATION_FETCH,
  GET_USER_LOCATION_SUCCESS,
  GetUserLocationFailed,
  GetUserLocationFetch,
  GetUserLocationResponse,
  GetUserLocationSuccess,
  PermissionStatusTypes,
  PUSH_NOTIFICATION_PERMISSION_REQUEST,
  PUSH_NOTIFICATION_REGISTER_CLEAR,
  PUSH_NOTIFICATION_REGISTER_FAILED,
  PUSH_NOTIFICATION_REGISTER_FETCH,
  PUSH_NOTIFICATION_REGISTER_SUCCESS,
  PushNotificationRegistrationStatus,
  PushNotificationsRegisterFailed,
  PushNotificationsRegisterFetch,
  RegisterPinpointOptions, REQUEST_BLUETOOTH_ID, REQUEST_DEVICE_TOKEN, REQUEST_TAG_ID,
  SATO_AUTH_CLEAR,
  SATO_AUTH_FAILED,
  SATO_AUTH_FETCH,
  SATO_AUTH_SUCCESS,
  SatoAreaInfo,
  SatoAuthFailed,
  SatoAuthFailType,
  SatoAuthFetch,
  SatoAuthParam,
  SatoAuthResponse,
  SatoAuthSuccess,
  SatoFailureType,
  SET_BLUETOOTH_ID,
  SET_BT_PERMISSION_STATUS,
  SET_DEVICE_TOKEN,
  SET_FIRST_RUN,
  SET_FIRST_VISIT,
  SET_LANGUAGE,
  SET_PN_PERMISSION_STATUS,
  SET_PUSH_NOTIFICATION_REGISTRATION_STATUS,
  SET_SATO_TOKEN,
  SET_TAG_ID,
  SetBluetoothId,
  SetBtPermissionStatus,
  SetFirstVisit,
  SetLanguage,
  SetPnPermissionStatus,
  SetPushNotificationsRegistrationStatus,
  SetSatoToken,
  SetTagId,
  USER_CLEAR,
  USER_FAILED,
  USER_FETCH,
  USER_SUCCESS,
  UserActionTypes,
  UserFailed,
  UserFetch,
  UserRegistration,
  UserRegistrationStatus,
  GetLocation,
  GEO_LOCATION,
  LocationInfo,
} from './constants';
import { FailureType, SupportedLanguages } from '../../config/interface';

//#endregion

//#region INTERFACE

interface DeviceIdsInitialState {
  bluetoothId: string;
  tagId: string;
  deviceToken: string;
  satoToken: string;
  bluetoothPermissionStatus: PermissionStatusTypes;
  pushNotificationPermissionStatus: PermissionStatusTypes;
  isBluetoothBroadcasting: boolean;
  bluetoothIdLoading: boolean;
  tagIdLoading: boolean;
  deviceTokenLoading: boolean;
}

interface PushNotificationInitialState {
  pushNotificationRegistrationStatus: PushNotificationRegistrationStatus;
  pushNotificationRegistrationError: FailureType;
  pushNotificationRegisterParam: RegisterPinpointOptions;
}

interface UserInitialState {
  cognitoUserRegistrationStatus: UserRegistrationStatus;
  cognitoUserRegistrationParam: UserRegistration;
  cognitoUserRegistrationError: FailureType;
  locale: SupportedLanguages;
  previousLocale?: SupportedLanguages;
  firstMealVisit: boolean;
  firstShowerVisit: boolean;
  firstAppVisit: boolean;
}

interface GetUserLocationInitialState {
  getUserLocationParam: SatoAreaInfo;
  getUserLocationResponse: GetUserLocationResponse;
  getUserLocationError: SatoFailureType | FailureType;
  getUserLocationLoading: boolean;
}

interface SatoAuthInitialState {
  satoAuthParam?: SatoAuthParam;
  satoAuthResponse?: SatoAuthResponse;
  satoAuthError: SatoAuthFailType;
  satoAuthLoading: boolean;
}

interface LocationState {
  location: LocationInfo
}

export interface InitialState
  extends DeviceIdsInitialState,
    PushNotificationInitialState,
    UserInitialState,
    GetUserLocationInitialState,
    SatoAuthInitialState,
    LocationState {
  action: string;
  isFirstRun: boolean;
}

//#endregion

//#region INITIAL STATE

const deviceIdsInitialState: DeviceIdsInitialState = {
  bluetoothId: '',
  deviceToken: '',
  tagId: '',
  satoToken: '',
  bluetoothPermissionStatus: PermissionStatusTypes.NOT_GRANTED,
  pushNotificationPermissionStatus: PermissionStatusTypes.NOT_GRANTED,
  isBluetoothBroadcasting: false,
  bluetoothIdLoading: false,
  deviceTokenLoading: false,
  tagIdLoading: false,
};

const pushNotificationInitialState: PushNotificationInitialState = {
  pushNotificationRegistrationError: '',
  pushNotificationRegistrationStatus: PushNotificationRegistrationStatus.UNREGISTERED,
  pushNotificationRegisterParam: {
    DeviceToken: '',
    EndpointId: '',
    PreviousEndpointId: '',
  },
};

const userInitialState: UserInitialState = {
  cognitoUserRegistrationStatus: UserRegistrationStatus.NOT_REGISTERED,
  cognitoUserRegistrationParam: {
    username: '',
    password: '',
  },
  cognitoUserRegistrationError: '',
  locale: SupportedLanguages.JA,
  previousLocale: undefined,
  firstMealVisit: false,
  firstShowerVisit: false,
  firstAppVisit: false,
};

const getUserLocationInitialState: GetUserLocationInitialState = {
  getUserLocationError: '',
  getUserLocationLoading: false,
  getUserLocationParam: {
    token: '',
    userName: '',
    tagId: '',
  },
  getUserLocationResponse: {
    isError: '',
    processingCount: undefined,
    userInfo: [],
    tagId: '',
  },
};

const satoAuthInitialState: SatoAuthInitialState = {
  satoAuthError: '',
  satoAuthLoading: false,
  satoAuthParam: undefined,
  satoAuthResponse: undefined,
};

const locationState: LocationState = {
  location: {
    Latitude: 0,
    Longitude: 0,
  }
}

const initialState: InitialState = {
  ...deviceIdsInitialState,
  ...pushNotificationInitialState,
  ...userInitialState,
  ...getUserLocationInitialState,
  ...satoAuthInitialState,
  ...locationState,
  action: '',
  isFirstRun: true,
};


//#endregion

//#region REDUCER

function userReducer(state = initialState, action: UserActionTypes): InitialState {
  const actions = {
    [REQUEST_BLUETOOTH_ID]: (): InitialState => ({
      ...state,
      action: action.type,
      // bluetoothIdLoading: true,
    }),
    [REQUEST_TAG_ID]: (): InitialState => ({
      ...state,
      action: action.type,
      // tagIdLoading: true,
    }),
    [REQUEST_DEVICE_TOKEN]: (): InitialState => ({
      ...state,
      action: action.type,
      // deviceTokenLoading: true,
    }),
    [SET_BLUETOOTH_ID]: (): InitialState => ({
      ...state,
      action: action.type,
      bluetoothId: (action as SetBluetoothId).payload,
      // bluetoothIdLoading: false,
    }),
    [SET_TAG_ID]: (): InitialState => ({
      ...state,
      action: action.type,
      tagId: (action as SetTagId).payload,
      // tagIdLoading: false,
    }),
    [SET_DEVICE_TOKEN]: (): InitialState => ({
      ...state,
      action: action.type,
      deviceToken: (action as SetBluetoothId).payload,
      // deviceTokenLoading: false,
    }),
    [PUSH_NOTIFICATION_REGISTER_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      pushNotificationRegistrationStatus: PushNotificationRegistrationStatus.REGISTERING,
      pushNotificationRegisterParam: (action as PushNotificationsRegisterFetch).payload,
    }),
    [PUSH_NOTIFICATION_REGISTER_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      pushNotificationRegistrationStatus: PushNotificationRegistrationStatus.REGISTERED,
    }),
    [PUSH_NOTIFICATION_REGISTER_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      pushNotificationRegistrationStatus: PushNotificationRegistrationStatus.REGISTRATION_FAILED,
      pushNotificationRegistrationError: (action as PushNotificationsRegisterFailed).payload,
    }),
    [PUSH_NOTIFICATION_REGISTER_CLEAR]: (): InitialState => ({
      ...state,
      ...pushNotificationInitialState,
      action: action.type,
    }),
    [USER_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      cognitoUserRegistrationStatus: UserRegistrationStatus.REGISTERING,
      cognitoUserRegistrationParam: (action as UserFetch).payload,
    }),
    [USER_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      cognitoUserRegistrationStatus: UserRegistrationStatus.REGISTERED,
    }),
    [USER_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      cognitoUserRegistrationStatus: UserRegistrationStatus.REGISTRATION_FAILED,
      cognitoUserRegistrationError: (action as UserFailed).payload,
    }),
    [USER_CLEAR]: (): InitialState => ({
      ...state,
      ...userInitialState,
      action: action.type,
    }),
    [SET_FIRST_RUN]: (): InitialState => ({
      ...state,
      action: action.type,
      isFirstRun: false,
    }),
    [SET_PUSH_NOTIFICATION_REGISTRATION_STATUS]: (): InitialState => ({
      ...state,
      action: action.type,
      pushNotificationRegistrationStatus: (action as SetPushNotificationsRegistrationStatus).payload,
    }),
    [SET_LANGUAGE]: (): InitialState => ({
      ...state,
      action: action.type,
      previousLocale: state.locale,
      locale: (action as SetLanguage).payload,
    }),
    [SET_FIRST_VISIT]: (): InitialState => ({
      ...state,
      action: action.type,
      ...((action as SetFirstVisit).payload === FirstVisitType.Meals && { firstMealVisit: !state.firstMealVisit }),
      ...((action as SetFirstVisit).payload === FirstVisitType.Shower && { firstShowerVisit: !state.firstShowerVisit }),
      ...((action as SetFirstVisit).payload === FirstVisitType.App && { firstAppVisit: !state.firstAppVisit }),
    }),
    [GET_USER_LOCATION_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      getUserLocationLoading: true,
      getUserLocationParam: (action as GetUserLocationFetch).payload,
    }),
    [GET_USER_LOCATION_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      getUserLocationLoading: false,
      getUserLocationResponse: (action as GetUserLocationSuccess).payload,
    }),
    [GET_USER_LOCATION_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      getUserLocationLoading: false,
      getUserLocationError: (action as GetUserLocationFailed).payload,
    }),
    [GET_USER_LOCATION_CLEAR]: (): InitialState => ({
      ...state,
      ...getUserLocationInitialState,
      action: action.type,
    }),
    [SATO_AUTH_FETCH]: (): InitialState => ({
      ...state,
      action: action.type,
      satoAuthLoading: true,
      satoAuthParam: (action as SatoAuthFetch).payload,
    }),
    [SATO_AUTH_SUCCESS]: (): InitialState => ({
      ...state,
      action: action.type,
      satoAuthLoading: false,
      satoAuthResponse: (action as SatoAuthSuccess).payload,
    }),
    [SATO_AUTH_FAILED]: (): InitialState => ({
      ...state,
      action: action.type,
      satoAuthLoading: false,
      satoAuthError: (action as SatoAuthFailed).payload,
    }),
    [SATO_AUTH_CLEAR]: (): InitialState => ({
      ...state,
      ...satoAuthInitialState,
      action: action.type,
    }),
    [SET_SATO_TOKEN]: (): InitialState => ({
      ...state,
      action: action.type,
      satoToken: (action as SetSatoToken).payload,
    }),
    [BLUETOOTH_PERMISSION_REQUEST]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [PUSH_NOTIFICATION_PERMISSION_REQUEST]: (): InitialState => ({
      ...state,
      action: action.type,
    }),
    [SET_BT_PERMISSION_STATUS]: (): InitialState => ({
      ...state,
      action: action.type,
      bluetoothPermissionStatus: (action as SetBtPermissionStatus).payload,
    }),
    [SET_PN_PERMISSION_STATUS]: (): InitialState => ({
      ...state,
      action: action.type,
      pushNotificationPermissionStatus: (action as SetPnPermissionStatus).payload,
    }),
    [GEO_LOCATION]: (): InitialState => ({
      ...state,
      action: action.type,
      location: (action as GetLocation).payload,
    }),
    DEFAULT: (): InitialState => state,
  };

  return (actions[action.type] || actions.DEFAULT)();
}

export default userReducer;

//#endregion
