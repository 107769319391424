//#region IMPORTS

// PACKAGE IMPORTS
import React, { Component } from 'react';
import PubSub from '@aws-amplify/pubsub';
import { Result } from '@zxing/library';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
// LOCAL CONFIG
import { messages } from '../../../../lang/locales';
import {
  FirstVisitType,
  PermissionStatusTypes,
  PushNotificationRegistrationStatus,
  RegisterPinpointOptions,
  SatoAuthParam,
  SatoAuthResponse,
  SET_LANGUAGE,
  USER_SUCCESS,
  UserActionTypes,
  UserRegistration,
  UserRegistrationStatus,
} from '../../../user/constants';
import { amplifyConfig } from '../../../../config/config';
import {
  COGNITO_CREDENTIALS,
  NEW_SCREEN_URL,
  NEW_SCREEN_URL_WITH_PARAM,
  NewUrlWithParam,
  WrapperActionTypes,
  AppVersion,
} from '../../constants';
import { SupportedLanguages } from '../../../../config/interface';
// LOCAL COMPONENTS
import QRReader from '../../../../components/QRReader';
import { Button } from '../../../../components';
import { Modal } from '../../../../components';
import './Wrapper.scss';
import './Home.scss';

//#endregion

//#region PROPS & STATE

export interface DispatchProps {
  ReceivedActionFromNative(payload: string): WrapperActionTypes;
  UserFetch(payload: UserRegistration): UserActionTypes;
  SetBluetoothId(payload: string): UserActionTypes;
  SetTagId(payload: string): UserActionTypes;
  BluetoothBroadcastStart(): WrapperActionTypes;
  RequestBluetoothBroadcastStart(): WrapperActionTypes;
  SetFirstRun(): UserActionTypes;
  RequestBluetoothId(): UserActionTypes;
  RequestTagId(): UserActionTypes;
  RequestDeviceToken(): UserActionTypes;
  PushNotificationRegisterFetch(payload: RegisterPinpointOptions): UserActionTypes;
  SetFirstVisit(payload: FirstVisitType): UserActionTypes;
  ConsoleLog(payload: any): WrapperActionTypes;
  SatoAuthFetch(payload: SatoAuthParam): UserActionTypes;
  SetSatoToken(payload: string): UserActionTypes;
  PushNotificationPermissionRequest(): UserActionTypes;
  VersionFetch(): WrapperActionTypes;
  SetAbleToBook(): WrapperActionTypes;
  RequestReStart(): WrapperActionTypes;
  SetLanguage(payload: SupportedLanguages): UserActionTypes;
}

export interface StateProps {
  userAction: string;
  userRegistrationStatus: UserRegistrationStatus;
  pushNotificationRegistrationStatus: PushNotificationRegistrationStatus;
  locale: SupportedLanguages;
  previousLocale?: SupportedLanguages;
  bluetoothId: string;
  isBroadcasting: boolean;
  tagId: string;
  isFirstRun: boolean;
  deviceToken: string;
  firstAppVisit: boolean;
  satoAuthResponse?: SatoAuthResponse;
  satoToken: string;
  bluetoothPermissionStatus: PermissionStatusTypes;
  pushNotificationPermissionStatus: PermissionStatusTypes;
  version: AppVersion;
  isAbleToBook: boolean;
  bluetoothIdLoading: boolean;
  tagIdLoading: boolean;
  deviceTokenLoading: boolean;
  action: string;
  newUrl: string;
  newUrlWithParam: NewUrlWithParam;
}

type Props = DispatchProps & StateProps & RouteComponentProps;

const InitialState = {
  successfulRegistration: false,
  qrModalVisible: false,
};

type State = typeof InitialState;

//#endregion

//#region MAIN CLASS

class Wrapper extends Component<Props, State> {
  qrModal: React.RefObject<Modal>;
  constructor(props: Props) {
    super(props);
    amplifyConfig();
    PubSub.configure({});

    moment.locale(this.props.locale);

    this.state = InitialState;
    this.qrModal = React.createRef();
    this.handleReduxAction = this.handleReduxAction.bind(this);
  }

  componentDidMount(): void {
    // もしかしたら最初にガイドを出す、という話になるかもしれないので
    // if (this.props.isFirstRun) {
    //   this.props.SetFirstRun();
    // }

    this.props.UserFetch(COGNITO_CREDENTIALS);

    this.props.RequestBluetoothBroadcastStart();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (this.props.userAction !== prevProps.userAction) {
      this.handleReduxAction(this.props.userAction);
    }

    if (this.props.action !== prevProps.action) {
      if (this.props.action === NEW_SCREEN_URL || this.props.action === NEW_SCREEN_URL_WITH_PARAM) {
        this.handleReduxAction(this.props.action);
      }
    }

    if (prevState.qrModalVisible !== this.state.qrModalVisible) {
      if (this.state.qrModalVisible) {
        this.qrModal.current && this.qrModal.current.openModal();
      } else {
        this.qrModal.current && this.qrModal.current.closeModal();
      }
    }

  }

  handleReduxAction(action: string): void {
    const actions = {
      [USER_SUCCESS as string]: (): void => {
        if (!this.props.isBroadcasting && this.props.bluetoothId !== '') {
          this.props.RequestBluetoothBroadcastStart();
        }
      },
      [SET_LANGUAGE as string]: (): void => {
        this.props.PushNotificationRegisterFetch({
          DeviceToken: this.props.deviceToken,
          EndpointId: `${this.props.tagId}-${this.props.locale.toString()}`,
          PreviousEndpointId: `${this.props.tagId}-${
            this.props.previousLocale ? this.props.previousLocale.toString() : SupportedLanguages.JA
          }`,
        });
      },
      DEFAULT: (): void => {},
    };

    return (actions[action] || actions.DEFAULT)();
  }

  handleQRReader(result:Result): void{
    document.location.href=result.getText();
  }


  renderQRReaderModal(): React.ReactElement {
    return (
      <>
        <QRReader 
          callbackFnc={this.handleQRReader} 
          handleShowQRReaderModal={()=>{this.setState({qrModalVisible:false})}}
        />
      </>
    );
  }

  render(): JSX.Element {
    return (
      <IntlProvider locale={this.props.locale} messages={messages[this.props.locale]}>
        <div className="wrapper" id="wrapper">
          <Router basename={process.env.NODE_ENV==="development"?"/hnd":process.env.PUBLIC_URL}>
          <div className="wrapper__content">
            <QRReader 
              callbackFnc={this.handleQRReader} 
              handleShowQRReaderModal={()=>{this.setState({qrModalVisible:false})}}
            />
            <Button
              className="qrreader-button-language"
              label={this.props.locale === 'ja' ? "sidebarEnglish" : "sidebarJapanese"}
              onClick={(): void => {
                if(this.props.locale === 'en'){
                  this.props.SetLanguage(SupportedLanguages.JA);
                }else{
                  this.props.SetLanguage(SupportedLanguages.EN);
                }
              }}
            />
          </div>
          </Router>
        </div>
      </IntlProvider>
    );
  }
}

export default withRouter(Wrapper);

//#endregion
