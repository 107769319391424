//#region IMPORTS

import {
  BLUETOOTH_BROADCAST_START,
  BLUETOOTH_BROADCAST_STOP,
  CONSOLE_LOG,
  NEW_BLUETOOTH_ID,
  NEW_SCREEN_URL,
  NEW_SCREEN_URL_WITH_PARAM,
  NEW_TAG_ID,
  NewUrlWithParam,
  RECEIVED_ACTION_FROM_NATIVE,
  RECEIVED_ACTION_FROM_NATIVE_CLEAR,
  RECEIVED_ACTION_FROM_NATIVE_ERROR,
  RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR,
  REQUEST_BLUETOOTH_BROADCAST_START,
  SEND_ACTION_TO_NATIVE,
  SEND_ACTION_TO_NATIVE_CLEAR,
  SEND_ACTION_TO_NATIVE_ERROR,
  SEND_ACTION_TO_NATIVE_ERROR_CLEAR,
  SET_ABLE_TO_BOOK,
  SET_MESSAGE_QUEUE,
  UNKNOWN_ACTION,
  VERSION_FETCH,
  VERSION_SUCCESS,
  FEATURE_FETCH,
  FEATURE_SUCCESS,
  FeatureItem,
  WrapperActionTypes,
  REQUEST_RESTART,
  AppVersion,
} from './constants';

//#endregion

//#region ACTION CREATORS

//#region XAMARIN COMMUNICATION

// Send Action To Native
export const SendActionToNative = (payload: string): WrapperActionTypes => ({
  type: SEND_ACTION_TO_NATIVE,
  payload,
});

export const SendActionToNativeClear = (): WrapperActionTypes => ({
  type: SEND_ACTION_TO_NATIVE_CLEAR,
});

export const SendActionToNativeError = (payload: string): WrapperActionTypes => ({
  type: SEND_ACTION_TO_NATIVE_ERROR,
  payload,
});

export const SendActionToNativeErrorClear = (): WrapperActionTypes => ({
  type: SEND_ACTION_TO_NATIVE_ERROR_CLEAR,
});

// Received Action from Native
export const ReceivedActionFromNative = (payload: string): WrapperActionTypes => ({
  type: RECEIVED_ACTION_FROM_NATIVE,
  payload,
});

export const ReceiveActionFromNativeClear = (): WrapperActionTypes => ({
  type: RECEIVED_ACTION_FROM_NATIVE_CLEAR,
});

export const ReceiveActionFromNativeError = (payload: string): WrapperActionTypes => ({
  type: RECEIVED_ACTION_FROM_NATIVE_ERROR,
  payload,
});

export const ReceiveActionFromNativeErrorClear = (): WrapperActionTypes => ({
  type: RECEIVED_ACTION_FROM_NATIVE_ERROR_CLEAR,
});

// Bluetooth
export const NewBluetoothId = (payload: string): WrapperActionTypes => ({
  type: NEW_BLUETOOTH_ID,
  payload,
});

export const NewTagId = (payload: string): WrapperActionTypes => ({
  type: NEW_TAG_ID,
  payload,
});

export const BluetoothBroadcastStart = (): WrapperActionTypes => ({
  type: BLUETOOTH_BROADCAST_START,
});

export const BluetoothBroadcastStop = (): WrapperActionTypes => ({
  type: BLUETOOTH_BROADCAST_STOP,
});

export const UnknownAction = (payload: unknown): WrapperActionTypes => ({
  type: UNKNOWN_ACTION,
  payload,
});
//#endregion

//#region VERSONING
export const VersionFetch = (): WrapperActionTypes => ({
  type: VERSION_FETCH,
});
export const VersionSuccess = (payload: AppVersion): WrapperActionTypes => ({
  type: VERSION_SUCCESS,
  payload,
});
//#endregion

//#region VERSONING
export const FeatureFetch = (): WrapperActionTypes => ({
  type: FEATURE_FETCH,
});
export const FeatureSuccess = (payload: FeatureItem[]): WrapperActionTypes => ({
  type: FEATURE_SUCCESS,
  payload,
});
//#endregion

//#region SUBSCRIPTION

//#endregion

//#region MISC

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ConsoleLog = (payload: any): WrapperActionTypes => ({
  type: CONSOLE_LOG,
  payload,
});

export const RequestBluetoothBroadcastStart = (): WrapperActionTypes => ({
  type: REQUEST_BLUETOOTH_BROADCAST_START,
});

export const SetAbleToBook = (): WrapperActionTypes => ({
  type: SET_ABLE_TO_BOOK,
});

export const SetMessageQueue = (payload: string[]): WrapperActionTypes => ({
  type: SET_MESSAGE_QUEUE,
  payload,
});

export const NewScreenUrl = (payload: string): WrapperActionTypes => ({
  type: NEW_SCREEN_URL,
  payload,
});

export const NewScreenUrlWithParam = (payload: NewUrlWithParam): WrapperActionTypes => ({
  type: NEW_SCREEN_URL_WITH_PARAM,
  payload,
});

export const RequestReStart = (): WrapperActionTypes => ({
  type: REQUEST_RESTART,
});
//#endregion

//#endregion
