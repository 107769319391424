//#region IMPORTS
// PACKAGE IMPORT
import React, {useEffect} from 'react';
import HomeBg from '../../modules/home/assets/home-bg.png';
import { injectIntl, FormattedMessage } from 'react-intl';
import { BrowserMultiFormatReader } from '@zxing/library';

// LOCAL COMPONENT
import { Frame } from './QRReaderFrame';
import './_index.scss';

//#endregion

//#region INTERFACE

function QRReader({callbackFnc, handleShowQRReaderModal}:any): React.ReactElement{
  // initialization for camera device

  useEffect(()=>{
    const codeReader = new BrowserMultiFormatReader();
    //const devices = window.navigator.mediaDevices.enumerateDevices();
    //console.log("devices: ", devices)
    codeReader.listVideoInputDevices()
      .then((videoInputDevices) => {
        codeReader.decodeOnceFromVideoDevice(undefined, 'video')
        .then((result) => {
          console.log("result: ", result)
          callbackFnc(result);
          // for debug
          // setResult(result.getText());
        })
        .catch((err) => {
          // for debug
          // setResult(err.toString());
          console.log("err: ", err)
        })
      })
      .catch((err) => {
        console.error(err)
      });
  },[callbackFnc]);
      
  return (
    <div className="qrreader"  style={{
      backgroundImage: `url(${HomeBg}`
    }}>
      <div className="wrap">
        <div className="qrreader-title">
          <h3><FormattedMessage id={'titleCongestion'} defaultMessage={'titleCongestion'} /></h3>
        </div>
        <div className="qrreader-inner">
          <div className="qrreader-wrap">
            <div className="qrreader-wrap__guide">
              <Frame/>
              <video id="video" autoPlay playsInline webkit-playsinline className="qrreader-wrap__guide__video" />
              <h5 className="qrreader-wrap__title">
                <FormattedMessage id={"titleQRReader"} defaultMessage={"titleQRReader"}/>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
  
}

export default injectIntl(QRReader);