//#region IMPORTS
// LOCAL CONFIG
import SagaWrapper from '../modules/wrapper/SagaWrapper';
import SagaCongestion from '../modules/congestion/SagaCongestion';
import SagaUser from '../modules/user/SagaUser';

//#endregion

export default [
  ...SagaWrapper,
  ...SagaCongestion,
  ...SagaUser,
];
