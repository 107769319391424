//#region IMPORT
// LOCAL CONFIG
import { Environment } from './interface';
import { AIRPORT_GEOLOCATIONS, JPN_GEOLOCATIONS } from './LoungeLocations';

//#endregion

//#region ENVIRONMENT
const dev: Environment = {
  poolId: 'ap-northeast-1:3f586941-1c11-4a6d-bfcb-f4ca5bc4ce58',
  region: 'ap-northeast-1',
  identityPoolRegion: 'ap-northeast-1',
  userPoolId: 'ap-northeast-1_lUmhKKfEU',
  mandatorySignIn: false,
  userPoolWebClientId: '6ha4a030hv84fmn1lrd9ne8mj4',
  appsyncEndpoint: 'https://wot6pkwnpzg3vmz6pljlljdbqe.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  appsyncRegion: 'ap-northeast-1',
  appsyncAuthenticationType: 'API_KEY',
  appsyncApiKey: 'da2-lkvuzpz5g5fuvgdperskintmai',
  credentialUserName: 'y24suzuki',
  credentialUserPassword: '!qaz2wsX',
  menuListUrl: 'https://dev-jal-content-641143465932-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/',
  featureUrl: 'https://dev-jal-content-641143465932-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/',
  versionUrl: 'https://dev-jal-content-641143465932-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/version.json',
  satoApiUrl: 'https://api.wellvill.com/d/roboten/JAL/v100',
  satoAuthUrl: 'https://auth.wellvill.com/auth/realms/JAL/protocol/openid-connect/token',
  airportsLocations: JPN_GEOLOCATIONS
};

const stage: Environment = {
  poolId: 'ap-northeast-1:35f62995-c7e4-483f-9868-82a7514fee0d',
  region: 'ap-northeast-1',
  identityPoolRegion: 'ap-northeast-1',
  userPoolId: 'ap-northeast-1_qTOobcsz2',
  mandatorySignIn: false,
  userPoolWebClientId: '1crvoa6iontu0ip1j4ic1heru5',
  appsyncEndpoint: 'https://4dnppm4kzng2vdlup5jouxbwoq.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  appsyncRegion: 'ap-northeast-1',
  appsyncAuthenticationType: 'API_KEY',
  appsyncApiKey: 'da2-flwazjf4xbdpnhwrdzf7gq3jta',
  credentialUserName: 'ueda',
  credentialUserPassword: '!qaz2wsX',
  menuListUrl: 'https://stage-jal-content-641143465932-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/',
  featureUrl: 'https://stage-jal-content-641143465932-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/',
  versionUrl: 'https://stage-jal-content-641143465932-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/version.json',
  satoApiUrl: 'https://api.wellvill.com/d/roboten/JAL/v100',
  satoAuthUrl: 'https://auth.wellvill.com/auth/realms/JAL/protocol/openid-connect/token',
  airportsLocations: JPN_GEOLOCATIONS
};

const prod: Environment = {
  poolId: 'ap-northeast-1:1f0d8f92-53a5-4dbf-bdcd-e59d4ffdcd13',
  region: 'ap-northeast-1',
  identityPoolRegion: 'ap-northeast-1',
  userPoolId: 'ap-northeast-1_3JKLYERJF',
  mandatorySignIn: false,
  userPoolWebClientId: '2u9474qfq3frs0c81bi2iqkcku',
  appsyncEndpoint: 'https://xsxqytot5zdkrnugms7m7b7k3m.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  appsyncRegion: 'ap-northeast-1',
  appsyncAuthenticationType: 'API_KEY',
  appsyncApiKey: 'da2-tcrnfzcn4fhbhgsoeqk3czleae',
  credentialUserName: 'guest',
  credentialUserPassword: 'q2-kJ}G{*bMF',
  menuListUrl: 'https://prod-jal-content-348982232738-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/',
  featureUrl: 'https://prod-jal-content-348982232738-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/',
  versionUrl: 'https://prod-jal-content-348982232738-ap-northeast-1.s3-ap-northeast-1.amazonaws.com/version.json',
  satoApiUrl: 'https://api.wellvill.com/d/roboten/JAL/v100',
  satoAuthUrl: 'https://auth.wellvill.com/auth/realms/JAL/protocol/openid-connect/token',
  airportsLocations: AIRPORT_GEOLOCATIONS
};

//#endregion

let env: Environment = dev;

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.trim() === 'production') {
  env = prod;
} else if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.trim() === 'stage') {
  env = stage;
}

export default env;