import React from 'react';

export const IconClose = (): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 30 30"
  >
    <path
      fill="#666466"
      fillRule="nonzero"
      d="M3.414.586A2 2 0 10.586 3.414L26.041 28.87a2 2 0 002.829-2.828L3.414.586z"
    ></path>
    <path
      fill="#666466"
      fillRule="nonzero"
      d="M28.87 3.414A2 2 0 1026.041.586L.586 26.042a2 2 0 002.828 2.828L28.87 3.414z"
    ></path>
  </svg>
);
