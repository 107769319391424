import gql from 'graphql-tag';

//#region LIST
const ListArea = gql`
  query ListArea($airport_id: String!, $limit: Int, $nextToken: String) {
    listArea(airport_id: $airport_id, limit: $limit, nextToken: $nextToken) {
      items {
        code
        congestion_level
        updated_at
      }
      nextToken
    }
  }
`;

export const queries = {
  ListArea,
};

//#endregion

//#region SUBSCRIPTIONS

const OnUpdateArea = gql`
  subscription OnUpdateAreaSubscription {
    onUpdateArea {
      code
      congestion_level
      updated_at
    }
  }
`;

export const subscription = {
  OnUpdateArea,
};

//#endregion
