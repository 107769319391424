//#region IMPORTS

import {
  PUSH_NOTIFICATION_REGISTER_CLEAR,
  PUSH_NOTIFICATION_REGISTER_FAILED,
  PUSH_NOTIFICATION_REGISTER_FETCH,
  PUSH_NOTIFICATION_REGISTER_SUCCESS,
  SET_BLUETOOTH_ID,
  SET_DEVICE_TOKEN,
  SET_FIRST_RUN,
  SET_PUSH_NOTIFICATION_REGISTRATION_STATUS,
  SET_LANGUAGE,
  SET_TAG_ID,
  USER_CLEAR,
  USER_FAILED,
  USER_FETCH,
  USER_SUCCESS,
  SET_MEAL_FIRST_VISIT,
  SET_FIRST_VISIT,
  SATO_AUTH_FETCH,
  SATO_AUTH_FAILED,
  SATO_AUTH_CLEAR,
  SATO_AUTH_SUCCESS,
  GET_USER_LOCATION_CLEAR,
  GET_USER_LOCATION_SUCCESS,
  GET_USER_LOCATION_FETCH,
  GET_USER_LOCATION_FAILED,
  SET_SATO_TOKEN,
  SET_BT_PERMISSION_STATUS,
  SET_PN_PERMISSION_STATUS,
  BLUETOOTH_PERMISSION_REQUEST,
  PUSH_NOTIFICATION_PERMISSION_REQUEST,
  GEO_LOCATION,
  REQUEST_TAG_ID,
  REQUEST_DEVICE_TOKEN,
  REQUEST_BLUETOOTH_ID,
} from './StringsUser';
import { FailureType, SupportedLanguages } from '../../../config/interface';

//#endregion

//#region GENERAL ENUM

export enum PushNotificationRegistrationStatus {
  UNREGISTERED = 'Unregistered',
  REGISTERING = 'Registering',
  REGISTERED = 'Registered',
  REGISTRATION_FAILED = 'RegistrationError',
}

export enum UserRegistrationStatus {
  NOT_REGISTERED = 'NOT_REGISTERED',
  REGISTERING = 'REGISTERING',
  REGISTERED = 'REGISTERED',
  REGISTRATION_FAILED = 'REGISTRATION_FAILED',
}

export enum FirstVisitType {
  App,
  Meals,
  Shower,
}

export enum PermissionStatusTypes {
  NOT_GRANTED = 'NOT_GRANTED',
  GRANTED = 'GRANTED',
  ERROR_OR_REJECTED = 'ERROR_OR_REJECTED',
}

//#endregion

//#region GENERAL INTERFACE

export interface RegisterPinpointOptions {
  DeviceToken: string;
  EndpointId: string;
  PreviousEndpointId?: string;
}

export interface CognitoUserData {
  Username: string;
  Pool: unknown;
  Storage?: unknown;
}

export interface CognitoUserSessionData {
  IdToken: unknown;
  AccessToken: unknown;
  RefreshToken?: unknown;
}

export type SatoFailureDetailInfo = {
  errorMessage: string;
  errorCode: string;
  stackTrace: string;
};

export type SatoFailureType = {
  isError: string;
  processingCount: number;
  detailInfo: SatoFailureDetailInfo;
};

export type SatoUserInfo = {
  storeName: string;
  areaId: string;
  areaName: string;
  token?: string;
};

export type SatoAreaInfo = {
  userName?: string;
  tagId: string;
  token: string;
  areaId?: string;
};

//#endregion

//#region ACTION TYPES

//#region DEVICE IDS

export interface RequestTagId {
  type: typeof REQUEST_TAG_ID;
}

export interface RequestBluetoothId {
  type: typeof REQUEST_BLUETOOTH_ID;
}

export interface RequestDeviceToken {
  type: typeof REQUEST_DEVICE_TOKEN;
}

export interface SetBluetoothId {
  type: typeof SET_BLUETOOTH_ID;
  payload: string;
}

export interface SetTagId {
  type: typeof SET_TAG_ID;
  payload: string;
}

export interface SetDeviceToken {
  type: typeof SET_DEVICE_TOKEN;
  payload: string;
}

export interface SetSatoToken {
  type: typeof SET_SATO_TOKEN;
  payload: string;
}

type DeviceIdActions =
  | SetBluetoothId
  | SetTagId
  | SetDeviceToken
  | SetSatoToken
  | RequestTagId
  | RequestBluetoothId
  | RequestDeviceToken;

//#endregion

//#region PUSH NOTIFICATIONS

export interface PushNotificationsRegisterFetch {
  type: typeof PUSH_NOTIFICATION_REGISTER_FETCH;
  payload: RegisterPinpointOptions;
}

export interface PushNotificationsRegisterSuccess {
  type: typeof PUSH_NOTIFICATION_REGISTER_SUCCESS;
}

export interface PushNotificationsRegisterFailed {
  type: typeof PUSH_NOTIFICATION_REGISTER_FAILED;
  payload: FailureType;
}

export interface SetPushNotificationsRegistrationStatus {
  type: typeof SET_PUSH_NOTIFICATION_REGISTRATION_STATUS;
  payload: PushNotificationRegistrationStatus;
}

export interface PushNotificationsRegisterClear {
  type: typeof PUSH_NOTIFICATION_REGISTER_CLEAR;
}

type PushNotificationActions =
  | PushNotificationsRegisterFetch
  | PushNotificationsRegisterSuccess
  | PushNotificationsRegisterFailed
  | SetPushNotificationsRegistrationStatus
  | PushNotificationsRegisterClear;

//#endregion

//#region USER

export type UserRegistration = { username: string; password: string };

export interface UserFetch {
  type: typeof USER_FETCH;
  payload: UserRegistration;
}
export interface UserSuccess {
  type: typeof USER_SUCCESS;
}
export interface UserFailed {
  type: typeof USER_FAILED;
  payload: FailureType;
}
export interface UserClear {
  type: typeof USER_CLEAR;
}

export interface SetFirstRun {
  type: typeof SET_FIRST_RUN;
}

type UserActions = UserFetch | UserSuccess | UserFailed | UserClear | SetFirstRun;

//#endregion

//#region LOCALE

export interface SetLanguage {
  type: typeof SET_LANGUAGE;
  payload: SupportedLanguages;
}

type LanguageActions = SetLanguage;

//#endregion

//#region FIRST VISIT

export interface SetMealFirstVisit {
  type: typeof SET_MEAL_FIRST_VISIT;
}

export interface SetFirstVisit {
  type: typeof SET_FIRST_VISIT;
  payload: FirstVisitType;
}

type VisitActions = SetFirstVisit;

//#endregion

//#region STRAPI

//#endregion

//#region SATO AUTH

// Fetch
export type SatoAuthParam = {
  client_id: string;
  username: string;
  password: string;
  grant_type: string;
};

export interface SatoAuthFetch {
  type: typeof SATO_AUTH_FETCH;
  payload: SatoAuthParam;
}

// Success
export type SatoAuthResponse = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  'not-before-policy': number;
  session_state: string;
  scope: string;
};

export interface SatoAuthSuccess {
  type: typeof SATO_AUTH_SUCCESS;
  payload: SatoAuthResponse;
}

// Failed
export type SatoAuthFailType =
  | FailureType
  | {
      error: string;
      error_description: string;
    };

export interface SatoAuthFailed {
  type: typeof SATO_AUTH_FAILED;
  payload: SatoAuthFailType;
}

// Clear
export interface SatoAuthClear {
  type: typeof SATO_AUTH_CLEAR;
}

type SatoAuthActions = SatoAuthFetch | SatoAuthSuccess | SatoAuthClear | SatoAuthFailed;

//#endregion

//#region GET USER CURRENT LOCATION

// Fetch
export interface GetUserLocationFetch {
  type: typeof GET_USER_LOCATION_FETCH;
  payload: SatoAreaInfo;
}

// Success
export type GetUserLocationResponse = {
  tagId: string;
  isError: string;
  processingCount?: number;
  userInfo: SatoUserInfo[];
};

export interface GetUserLocationSuccess {
  type: typeof GET_USER_LOCATION_SUCCESS;
  payload: GetUserLocationResponse;
}

// Failed
export interface GetUserLocationFailed {
  type: typeof GET_USER_LOCATION_FAILED;
  payload: SatoFailureType | FailureType;
}

// Clear
export interface GetUserLocationClear {
  type: typeof GET_USER_LOCATION_CLEAR;
}

export interface GetLocation {
  type: typeof GEO_LOCATION;
  payload: LocationInfo;
}

export interface LocationInfo {
  Latitude?: Number;
  Longitude?: Number;
}
  
type GetUserLocationActions =
  | GetUserLocationFetch
  | GetUserLocationSuccess
  | GetUserLocationFailed
  | GetUserLocationClear;

//#endregion

//#region DEVICE PERMISSION

export interface BluetoothPermissionRequest {
  type: typeof BLUETOOTH_PERMISSION_REQUEST;
}

export interface PushNotificationPermissionRequest {
  type: typeof PUSH_NOTIFICATION_PERMISSION_REQUEST;
}

export interface SetBtPermissionStatus {
  type: typeof SET_BT_PERMISSION_STATUS;
  payload: PermissionStatusTypes;
}

export interface SetPnPermissionStatus {
  type: typeof SET_PN_PERMISSION_STATUS;
  payload: PermissionStatusTypes;
}

type DevicePermissionActions =
  | BluetoothPermissionRequest
  | PushNotificationPermissionRequest
  | SetBtPermissionStatus
  | SetPnPermissionStatus;

//#endregion

export type UserActionTypes =
  | DeviceIdActions
  | PushNotificationActions
  | UserActions
  | LanguageActions
  | VisitActions
  | GetUserLocationActions
  | DevicePermissionActions
  | SatoAuthActions
  | GetLocation;

export type GetLocationType = GetLocation

//#endregion
