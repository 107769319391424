//#region IMPORTS

import env from '../../../config/environment';
import { SatoAuthParam } from './TypesUser';

//#endregion

//#region ACTION STRINGS

// Device IDs
export const REQUEST_TAG_ID = 'REQUEST_TAG_ID';
export const REQUEST_BLUETOOTH_ID = 'REQUEST_BLUETOOTH_ID';
export const REQUEST_DEVICE_TOKEN = 'REQUEST_DEVICE_TOKEN';
export const SET_BLUETOOTH_ID = 'SET_BLUETOOTH_ID';
export const SET_TAG_ID = 'SET_TAG_ID';
export const SET_DEVICE_TOKEN = 'SET_DEVICE_TOKEN';
export const SET_SATO_TOKEN = 'SET_SATO_TOKEN';

// Push Notifications
export const PUSH_NOTIFICATION_REGISTER_FETCH = 'PUSH_NOTIFICATION_REGISTER_FETCH';
export const PUSH_NOTIFICATION_REGISTER_SUCCESS = 'PUSH_NOTIFICATION_REGISTER_SUCCESS';
export const PUSH_NOTIFICATION_REGISTER_FAILED = 'PUSH_NOTIFICATION_REGISTER_FAILED';
export const PUSH_NOTIFICATION_REGISTER_CLEAR = 'PUSH_NOTIFICATION_REGISTER_CLEAR';
export const SET_PUSH_NOTIFICATION_REGISTRATION_STATUS = 'SET_PUSH_NOTIFICATION_REGISTRATION_STATUS';

// User
export const USER_FETCH = 'USER_FETCH';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILED = 'USER_FAILED';
export const USER_CLEAR = 'USER_CLEAR';

// Locale
export const SET_LANGUAGE = 'SET_LANGUAGE';

// Strapi
export const STRAPI_AUTH_FETCH = 'STRAPI_AUTH_FETCH';
export const STRAPI_AUTH_SUCCESS = 'STRAPI_AUTH_SUCCESS';
export const STRAPI_AUTH_FAILED = 'STRAPI_AUTH_FAILED';
export const STRAPI_AUTH_CLEAR = 'STRAPI_AUTH_CLEAR';

// Get User's Current Location
export const GET_USER_LOCATION_FETCH = 'GET_USER_LOCATION_FETCH';
export const GET_USER_LOCATION_SUCCESS = 'GET_USER_LOCATION_SUCCESS';
export const GET_USER_LOCATION_FAILED = 'GET_USER_LOCATION_FAILED';
export const GET_USER_LOCATION_CLEAR = 'GET_USER_LOCATION_CLEAR';

// User Initial Visit
export const SET_MEAL_FIRST_VISIT = 'SET_MEAL_FIRST_VISIT';
export const SET_SHOWER_FIRST_VISIT = 'SET_SHOWER_FIRST_VISIT';
export const SET_FIRST_VISIT = 'SET_FIRST_VISIT';

// Sato Auth
export const SATO_AUTH_FETCH = 'SATO_AUTH_FETCH';
export const SATO_AUTH_SUCCESS = 'SATO_AUTH_SUCCESS';
export const SATO_AUTH_FAILED = 'SATO_AUTH_FAILED';
export const SATO_AUTH_CLEAR = 'SATO_AUTH_CLEAR';

// Device Permission
export const BLUETOOTH_PERMISSION_REQUEST = 'BLUETOOTH_PERMISSION_REQUEST';
export const BLUETOOTH_PERMISSION_RESPONSE = 'BLUETOOTH_PERMISSION_RESPONSE';
export const PUSH_NOTIFICATION_PERMISSION_REQUEST = 'PUSH_NOTIFICATION_PERMISSION_REQUEST';
export const PUSH_NOTIFICATION_PERMISSION_RESPONSE = 'PUSH_NOTIFICATION_PERMISSION_RESPONSE';
export const SET_BT_PERMISSION_STATUS = 'SET_BT_PERMISSION_STATUS';
export const SET_PN_PERMISSION_STATUS = 'SET_PN_PERMISSION_STATUS';

//#endregion

//#region API

// SATO
export const SATO_API_URI = env.satoApiUrl;
export const GET_AREA_USERS_API = '/whois';
export const GET_USER_LOCATION_API = '/whereis';

// SATO AUTH
export const SATO_API_AUTH_URI = env.satoAuthUrl;
export const SATO_AUTH_PAYLOAD: SatoAuthParam = {
  client_id: 'jal-client',
  username: 'jaltok1015',
  password: 'JalTok1015',
  grant_type: 'password',
};

//#endregion

//#region MISC

export const SET_FIRST_RUN = 'SET_FIRST_RUN';

export const GEO_LOCATION = 'GEO_LOCATION';

export const MOCK_USER_INFO = [
  {
    areaId: 'MQKTG9sWHHw9QR9kjtJE',
    areaName: 'F4-0-RT01',
    storeName: 'SATO',
  },
];

//#endregion
